import React from 'react';
import '../theme.css';
import './InstagramGrid.css';

const img = (number) => <div key={`photo${number}`} className={`i${number} InstaImg`} />;
const numbers = [1, 2, 3, 4];

const handleClick = () => {
  window.location.href = 'https://www.instagram.com/lab_uhardilla/?hl=es';
};

const InstagramGrid = () => (
  <>
    <h2>Échale un ojo a nuestro Instagram</h2>
    <div className="columns wrap pointable" onClick={handleClick}>
      {numbers.map((number) => img(number))}
    </div>
  </>
);

export default InstagramGrid;
