import React from 'react';
import Content from '../components/Content/Content';
import InstagramGrid from '../components/InstagramGrid/InstagramGrid';
import Landing from '../components/Landing/Landing';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import StaticContentCovid from '../components/StaticContentCovid/StaticContentCovid';


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Landing />
    <Content>
      <StaticContentCovid />
    </Content>
    <Content>
      <InstagramGrid />
    </Content>
  </Layout>
);

export default IndexPage;
