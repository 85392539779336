import React from 'react';
import '../theme.css';
import './Content.css';

const Content = ({ children }) => (
  <div className="Content standardPadding">
    {children}
  </div>
);

export default Content;
