const LAZY_LOAD = (() => {
  const lazyLoad = (imageContainer, setImgLoaded, highQuality) => {
    console.log('image', highQuality);
    const _img = imageContainer.current;
    const newImg = new Image();
    newImg.onload = function () {
      _img.style.background = `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url("${this.src}")no-repeat center`;
      setImgLoaded(true);
    };
    newImg.src = highQuality;
  };

  return { lazyLoad };
})();

export default LAZY_LOAD;
