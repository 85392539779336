import React, { useEffect, useRef, useState } from 'react';
import LAZY_LOAD from '../../lazyLoad/lazyLoad';
import '../theme.css';
import './Landing.css';
import HQ from './landing.jpeg';


const Landing = () => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const imageContainer = useRef(null);

  useEffect(() => {
    LAZY_LOAD.lazyLoad(imageContainer, setImgLoaded, HQ);
  }, []);

  return (
    <div ref={imageContainer} className="Landing columns wrap">
      <div className="colL" />
      {(imgLoaded)
        && (
        <div className="colA">
          Somos un laboratorio de ideas artísticas, tecnológicas… ¡de todo!
          <br />
          <br />
          Realizamos diseño gráfico, diseño de interfaces de usuario, páginas web, desarrollo web, IoT y consultoría.
        </div>
        )}

    </div>
  );
};

export default Landing;
