import React from 'react';
import '../theme.css';
import './StaticContentCovid.css';

const StaticContentCovid = () => (
  <>
    <h2>COVID-19</h2>
    <div className="columns wrap">
      <div className="col">
        Debido a la situación de emergencia sanitaria actual, decidimos poner a disposición de la comunidad nuestras impresoras 3D para fabricar todo material necesario para nuestros sanitarios.
        <br />
        <br />
        <h3>
          ¿Eres sanitario o perteneces a algún centro de salud?
          <br />
          <br />
          ¡Contacta con nosotros para enviarte protecciones!
        </h3>
        <div className="columns">
          <a className="button" href="/contacto">ENVÍANOS UN MAIL</a>
        </div>
      </div>
      <div className="col">
        <div className="sanitarios" />
      </div>
    </div>
  </>
);

export default StaticContentCovid;
